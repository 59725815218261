.flight-banner-wrapper {
  margin: 0;
  padding: 0px;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.flight-banner-content {
  position: relative;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .live-banner-text {
  width: 40%;
  margin-bottom: 58px;
  padding: 3px;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 3px;
  text-align: center;
  text-shadow: 2px 2px 4px black;
} */


.logo {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50%;
}
.flyby-logo-banner {
  margin-top: 20px;
  width: 150px;
  height: 100px;
}

.flyby-text-banner {
  margin: 0;
  width: 110px;
  height: 100px;
}
