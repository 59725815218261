html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

:root {
  /* Fonts */
  --font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  --font-color-main: #ffffff;
  --yellow: #FFDE59;

  /* Background */
  --light-blue-bg: #244b8afa ;
  --medium-blue-bg: #03245a;
  --dark-blue-bg: #011a42;
  --white-bg:#ffffff;
  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  
  /* Icons */
  --light-blue-icon: #93c5fd;


  /* Live Banner */
  --banner-red-bg: #ff0000;
  --banner-white-font: #ffffff;
}
